import React from "react";
import { Col, Divider, Row, Typography } from "antd";
import LogoKairos from "../images/logo_kairos_bianco.png";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { BaseButton } from "../components";
import { useAuthState } from "../providers/AuthProvider";

const Home = () => {
  const { Title } = Typography;
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useAuthState();

  if (user) {
    history.push("/welcome");
  }
  return (
    <div className="container">
      <Row
        align="middle"
        justify="center"
        className="card-style p-0"
        style={{
          border: "0px solid",
          margin: "auto",
          borderRadius: "20px",
        }}
      >
        <Col
          className="col-blue"
          xl={{ span: 12 }}
          md={{ span: 12 }}
          xs={{ span: 24 }}
          align="center"
        >
          <div style={{ padding: "58px" }}>
            <Title className="title_home" level={2}>
              {t("welcome_to_edukairos_platform")}
            </Title>
            <div
              style={{
                textAlign: "left",
                color: "#fff",
                fontSize: "20px",
                lineHeight: "22px",
              }}
            >
              <p
                dangerouslySetInnerHTML={{
                  __html: t("message_home_edukairos_1", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              ></p>
              <p>{t("message_home_edukairos_2")}</p>
              <p>{t("message_home_edukairos_3")}</p>
            </div>
          </div>
        </Col>
        <Col
          className="col-lightblue"
          md={{ span: 12 }}
          xs={{ span: 24 }}
          align="center"
        >
          <div style={{ padding: "38px 58px 58px 58px" }}>
            <img
              src={LogoKairos}
              alt="Logo-Kairos"
              width="30%"
              style={{ marginBottom: "0.5em" }}
            />
            <Title
              className="title-blue"
              level={2}
              style={{
                textAlign: "center",
                lineHeight: "34px",
                textTransform: "uppercase",
                marginBottom: "0px",
              }}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: t("label_no_registered", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              ></span>
            </Title>
            <Row justify="center">
              <Col xl={{ span: 24 }} md={{ span: 22 }} xs={{ span: 18 }}>
                <p className="title-blu-home">{t("register_and_use_app")}</p>
                <Row justify="center" style={{ marginBottom: "20px" }}>
                  <Col
                    xxl={{ span: 14 }}
                    xl={{ span: 18 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                  >
                    <BaseButton
                      className="shadow-button"
                      type="primary"
                      shape="round"
                      size="large"
                      block
                      style={{ marginBottom: "10px" }}
                      onClick={() => history.push("/verifycodecompany")}
                    >
                      {t("unique_code").toUpperCase()}
                    </BaseButton>
                    <BaseButton
                      className="shadow-button"
                      type="primary"
                      shape="round"
                      size="large"
                      block
                      style={{ marginBottom: "0px" }}
                      onClick={() => history.push("/signup")}
                    >
                      {t("no_unique_code").toUpperCase()}
                    </BaseButton>
                  </Col>
                </Row>
                <Divider
                  style={{
                    minWidth: "20%",
                    width: "20%",
                    borderRadius: "20px",
                    border: "2px solid #035e86",
                    margin: "0px 0px 20px",
                    backgroundColor: "#035e86",
                  }}
                />
                <Title
                  className="title-blue"
                  level={2}
                  style={{
                    textAlign: "center",
                    lineHeight: "34px",
                    textTransform: "uppercase",
                    marginBottom: "0px",
                  }}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("already_registered", {
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  ></span>
                </Title>

                <p className="title-blu-home">{t("do_login")}</p>
                <Row justify="center">
                  <Col
                    xxl={{ span: 14 }}
                    xl={{ span: 18 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <BaseButton
                      className="shadow-button"
                      type="primary"
                      shape="round"
                      size="large"
                      block
                      style={{ marginBottom: "10px" }}
                      onClick={() => history.push("/login")}
                    >
                      {t("login").toUpperCase()}
                    </BaseButton>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default Home;
