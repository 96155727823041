import React from "react";

import { Redirect, useLocation } from "react-router-dom";
import { useAuthState } from "../providers/AuthProvider";
import { useTranslation } from "react-i18next";

const OAuthCallback = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { isLoading, processAuthParams } = useAuthState();

  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const processParams = async () => {
      try {
        await processAuthParams(location.search);
      } catch (err) {
        setError(err);
      }
    };

    processParams();
  }, []);

  if (isLoading) {
    return <h1>{t('authenticating')}</h1>;
  }

  if (error) {
    return <h1>{error.eCode}</h1>;
  }

  if (!isLoading && error === null) {
    return <Redirect to="/welcome" />;
  }

  return (
    <React.Fragment>
      <h1>Autenticazione in corso ...</h1>
    </React.Fragment>
  );
};
export default OAuthCallback;
