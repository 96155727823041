import React from "react";
import dayjs from "dayjs";

import { roles } from "../constants/domain";
import client from "../kairosClient";
import useAnalytics from "../hooks/useAnalytics";
import useStickyState from "../hooks/useStickyState";

const AuthContext = React.createContext();

const useAuthState = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }
  return context;
};

const AuthProvider = ({ children }) => {
  const utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);

  const { logLogin, logSignUp, METHODS } = useAnalytics();
  const [user, setUser] = useStickyState(null, "user");
  const [token, setToken] = useStickyState(null, "token");
  const [refreshToken, setRefreshToken] = useStickyState(null, "refresh_token");
  const [isLoading, setLoading] = React.useState(false);
  const [googleOAuthUrl, setGoogleOAuthUrl] = React.useState(null);
  const [isDoingSurvey, setIsDoingSurvey] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);

  const nullFunction = () => null;

  React.useEffect(() => {
    client.oauth
      .getGoogleOAuthUrl()
      .then((res) => res.json())
      .then((res) => setGoogleOAuthUrl(res.url));
  }, []);

  const { BASIC, INTERNAL, ADMIN } = roles;

  const login = (
    username,
    password,
    onSuccess = nullFunction,
    onError = nullFunction
  ) => {
    setLoading(true);
    client.user
      .login(username, password)
      .then(async (response) => {
        const BE_response = await response.json();
        console.log("sdsdsdsdsd", BE_response)

        if (BE_response.eCode) {
          console.error("AuthProvider.login error", BE_response); //ok gestito esternamente
          onError(response);
        } else {
          console.log("AuthProvider.login successful", BE_response);
          //setAsyncStorageData(USER_KEY, response);
          setUser(BE_response);
          setToken(BE_response.token);
          setRefreshToken(BE_response.refreshToken);
          onSuccess(username, password);
          logLogin(METHODS.EDUKAIROS);
        }
      })
      .catch((error) => {
        console.error("AuthProvider.login unpredictable error", error); //ok gestito espernamente
        onError(error);
      })
      .finally(() => setLoading(false));
  };

  const logout = () => {
    console.log("AuthProvider.logout");
    setUser(null);
    setToken(null);
    setRefreshToken(null);
  };

  const refresh = (onSuccess = nullFunction, onError = nullFunction) => {
    setLoading(true);
    client.user
      .getById(user.id, token)
      .then((response) => response.json())
      .then((response) => {
        if (response.eCode) {
          console.error("AuthProvider.refresh error", response);
          onError(response);
        } else {
          //setAsyncStorageData(USER_KEY, response);
          setUser(response);
          onSuccess();
        }
      })
      .catch((error) => {
        console.error("AuthProvider.refresh unpredictable error", error); //ok
        onError(error);
      })
      .finally(() => setLoading(false));
  };

  const signup = (
    email,
    password,
    confirmPassword,
    code,
    successCallback = nullFunction,
    errorCallback = nullFunction,
    doLogin = true,
    onErrorAuth0 = nullFunction
  ) => {
    setLoading(true);
    client.user
      .signup({
        email: email,
        password: password,
        confirmPassword: confirmPassword,
        code: code,
      })
      .then((res) => res.json())
      .then((response) => {
        if (response.eCode) {
          console.error("AuthProvider.signup error", response); //ok gestito esternamente
          errorCallback(response);
        } else {
          console.log("AuthProvider.signup success", response);
          logSignUp(METHODS.EDUKAIROS);

          if (doLogin) {
            login(email, password, nullFunction,
              //se registro utente (su db OK) che è già registrato su auth0
              () => {
                onErrorAuth0()
              });
          }
        }
      })
      .catch((error) => {
        console.error("AuthProvider.signup unpredictable error", error); //ok gestito esternamente
        errorCallback(error);
      })
      .finally(() => setLoading(false));
  };

  const signupWithCode = (
    email,
    password,
    confirmPassword,
    code,
    successCallback = nullFunction,
    errorCallback = nullFunction,
    doLogin = true,
    onErrorAuth0 = nullFunction
  ) => {
    setLoading(true);
    client.user
      .signupWithCode({
        email: email,
        password: password,
        confirmPassword: confirmPassword,
        code: code,
      })
      .then((res) => res.json())
      .then((response) => {
        if (response.eCode) {
          console.error("AuthProvider.signupWithCode error", response); //ok gestito esternamente
          errorCallback(response);
        } else {
          console.log("AuthProvider.signupWithCode success", response);
          logSignUp(METHODS.EDUKAIROS);
          if (doLogin) {
            login(email, password, successCallback, () => { onErrorAuth0() });
          }
        }
      })
      .catch((error) => {
        console.error("AuthProvider.signup unpredictable error", error); //ok gestito esternamente
        errorCallback(error);
      })
      .finally(() => setLoading(false));
  };

  const newVerificationCode = (
    onSuccess = nullFunction,
    onError = nullFunction
  ) => {
    client.user.generateCode(user.id, token).then((res) => {
      if (res.ok) {
        onSuccess();
      } else {
        onError();
      }
    });
  };

  const verifyUser = (
    code,
    onSuccess = nullFunction,
    onError = nullFunction
  ) => {
    setLoading(true);
    client.user
      .verifyCode(user.id, code, token)
      .then((res) => {
        if (res.ok) {
          return res.text();
        }
        return res.json();
      })
      .then((res) => {
        if (res.eCode) {
          onError(res);
        } else {
          const newUser = Object.assign({}, user);
          newUser.verified = true;
          setUser(newUser);
          //console.log(newUser);
          //setAsyncStorageData(USER_KEY, newUser);
          onSuccess();
        }
      })
      .finally(() => setLoading(false));
  };

  const verifyCompany = (
    code,
    onSuccess = nullFunction,
    onError = nullFunction
  ) => {
    setLoading(true);
    client.company
      .verifyCodeCompany(code)
      .then((res) => {
        if (res.ok) {
          console.log(res);
          return res.json();
        }
        return res.json();
      })
      .then((res) => {
        if (res.eCode) {
          onError(res);
        } else {
          //const newUser = Object.assign({}, user);
          //newUser.verified = true;
          //setUser(newUser);
          //console.log(newUser);
          //setAsyncStorageData(USER_KEY, newUser);
          onSuccess();
        }
      })
      .finally(() => setLoading(false));
  };

  const recoverPassword = (
    email,
    onSuccess = nullFunction,
    onError = nullFunction
  ) => {
    setLoading(true);
    client.user
      .resetPassword(email)
      .then((res) => {
        console.log("AuthProvider.recoverPassword", res);
        if (res.ok) {
          onSuccess();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        //console.log('AuthProvider.recoverPassword ', res);
        if (res?.eCode) {
          onError(res);
        }
      })
      .catch((err) => {
        console.error("AuthProvider.recoverPassword catched err", err); //ok
        onError(err);
      })
      .finally(() => setLoading(false));
  };

  const changePassword = (
    currentPwd,
    pwd,
    confirmPwd,
    onSuccess = nullFunction,
    onError = nullFunction
  ) => {
    const values = {
      currentPassword: currentPwd,
      password: pwd,
      confirmPassword: confirmPwd,
    };
    setLoading(true);
    client.user
      .changePassword(user.id, values, token)
      .then((res) => res.json())
      .then((response) => {
        console.log("ChangePassword.onSubmit response", response);
        if (response.eCode) {
          console.error("ChangePassword.onSubmit error", response); //ok
          if (response.eCode === "1006") {
            response.eCode = "1006_bis";
          }
          //logUserUpdate(STATUS.ERROR);
          //showError(response);
          onError(response);
        } else {
          console.log("ChangePassword.onSubmit successful", response);
          //showModal(t("success"), t("change_password_success"));
          //logUserUpdate(STATUS.SUCCESS);
          onSuccess();
        }
      })
      .catch((error) => {
        //showError(error);
        //logUserUpdate(STATUS.ERROR);
        console.error("ChangePassword.onSubmit unpredictable error", error); //ok
        onError(error);
      })
      .finally(() => setLoading(false));
  };

  const changeEmail = (
    newEmail,
    confirmEmail,
    password,
    onSuccess = nullFunction,
    onError = nullFunction
  ) => {
    const data = {
      newEmail: newEmail,
      confirmEmail: confirmEmail,
      password: password,
    };
    setLoading(true);
    client.user
      .changeEmail(user.id, data, token)
      .then((res) => res.json())
      .then((response) => {
        console.log("AuthProvider.ChangeEmail.onSubmit response", response);
        if (response.eCode) {
          console.error("AuthProvider.ChangeEmail.onSubmit error", response); //ok
          if (response.eCode === "1006") {
            response.eCode = "1006_bis";
          }
          //logUserUpdate(STATUS.ERROR);
          onError(response);
        } else {
          console.log("AuthProvider.ChangeEmail.onSubmit successful", response);
          //logUserUpdate(STATUS.SUCCESS);
          onSuccess();
        }
      })
      .catch((error) => {
        //console.error("ChangeEmail.onSubmit unpredictable error", error); //ok
        //logUserUpdate(STATUS.ERROR);
        onError(error);
      })
      .finally(() => setLoading(false));
  };

  const processAuthParams = (params) => {
    setLoading(true);
    const searchParams = new URLSearchParams(params);
    const t = searchParams.get("token");
    const rt = searchParams.get("refreshToken");
    getOrCreateUser(t, rt, true);
  };

  const getOrCreateUser = (accessToken, rToken, tryToCreateUser) => {
    client.user
      .getMyself(accessToken)
      .then((res) => res.json())
      .then((res) => {
        if (res.eCode) {
          if (res.eCode === "202") {
            //Utente non presente nel DB
            if (tryToCreateUser) {
              console.log(
                "AuthProvider.getOrCreateUser user not found, try to create"
              );
              client.user
                .signupMyself(accessToken)
                .then((response) => response.json())
                .then((response) => {
                  if (response.eCode) {
                    console.error(
                      "AuthProvider.getOrCreateUser unpredictable error creating user",
                      response
                    ); //ok
                    //showError(response);
                  } else {
                    //Creato l'utente provo a rileggerlo
                    logSignUp(METHODS.GOOGLE);
                    getOrCreateUser(accessToken, rToken, false);
                  }
                });
            } else {
              console.error(
                "AuthProvider.getOrCreateUser cannot create user (second try failed)"
              ); //ok
              //showError(res);
            }
          } else {
            console.error(
              "AuthProvider.getOrCreateUser unpredictable error",
              res
            ); //ok
            //showError({ eCode: 999 });
          }
        } else {
          console.log("AuthProvider.login from social success:", res);
          setToken(accessToken);
          setRefreshToken(rToken);
          setUser(res);
          setLoading(false);
          logLogin(METHODS.GOOGLE);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const surveyProgress = () => {
    if (user) {
      if (user.totQuestion === user.totAnswer) {
        return 100;
      }

      return Math.floor((user.totAnswer * 100) / user.totQuestion);
    }

    return null;
  };

  const isProfileExpired = () => {
    if (!user) {
      return null;
    }

    const now = dayjs.utc().startOf("day");
    const profileDate = dayjs.utc(user.expire).startOf("day");
    return now.isAfter(profileDate);
  };

  const isLogged = () => user !== null;

  const isAdmin = () => user && user.roleId === ADMIN.id;

  return (
    <AuthContext.Provider
      value={{
        //const
        googleOAuhtUrl: googleOAuthUrl,
        isLoading,
        token,
        user,

        //function
        changeEmail,
        changePassword,
        isAdmin,
        isDoingSurvey,
        isLogged,
        isProfileExpired,
        login,
        logout,
        newVerificationCode,
        processAuthParams,
        recoverPassword,
        refresh,
        setIsDoingSurvey,
        signup,
        signupWithCode,
        surveyProgress,
        verifyUser,
        verifyCompany,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, useAuthState };
