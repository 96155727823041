const baseUrl = process.env.REACT_APP_API_BASE_URL;

const tOut = 15000;

const timeout = (ms, promise) => {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      reject({ eCode: 999, message: "timeout" });
    }, ms);
    promise.then(resolve, reject);
  });
};

const get = (path, jwt) => {
  const requestData = {
    method: "GET",
  };
  if (jwt) {
    requestData.headers = {
      Authorization: `Bearer ${jwt}`,
    };
  }
  console.log(`KairosClient.get ${baseUrl.concat(path)}`, requestData);
  return timeout(tOut, fetch(baseUrl.concat(path), requestData));
};

const post = (path, data, jwt) => {
  const requestData = {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  if (jwt) {
    requestData.headers.Authorization = `Bearer ${jwt}`;
  }
  console.log(`KairosClient.post ${baseUrl.concat(path)}`, requestData);
  return timeout(tOut, fetch(baseUrl.concat(path), requestData));
};

const put = (path, data = {}, jwt) => {
  const requestData = {
    method: "PUT",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  if (jwt) {
    requestData.headers.Authorization = `Bearer ${jwt}`;
  }
  console.log(`KairosClient.put ${baseUrl.concat(path)}`, requestData);
  return timeout(tOut, fetch(baseUrl.concat(path), requestData));
};

const del = (path, jwt) => {
  const requestData = {
    method: "DELETE",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (jwt) {
    requestData.headers.Authorization = `Bearer ${jwt}`;
  }
  console.log(`KairosClient.del ${baseUrl.concat(path)}`, requestData);
  return timeout(tOut, fetch(baseUrl.concat(path), requestData));
};

const download = (path, filename, token, callBack = () => null) => {
  const requestData = {
    method: "GET",
  };
  if (token) {
    requestData.headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  console.log(`kairosClient.download ${baseUrl.concat(path)}`, requestData);
  fetch(baseUrl.concat(path), requestData)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("target", "_blank");
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    })
    .finally(() => callBack())
    .catch((error) => console.error(`kairosClient.download error `, error));
};

const user = {
  changeEmail: (id, data, token) => put(`/users/${id}/email`, data, token),
  changePassword: (id, data, token) =>
    put(`/users/${id}/password`, data, token),
  checkWebToken: (token) => get(`/users/webtoken/${token}`),
  signup: (data) => post("/users/", data),
  signupWithCode: (data) => post(`/users/company`, data),
  signupMyself: (token) => post("/users/me", {}, token),
  generateCode: (userId, token) => post(`/users/${userId}/verify`, {}, token),
  getById: (id, token) => get(`/users/${id}`, token),
  getMyself: (token) => get("/users/me", token),
  login: (username, password) =>
    post("/users/login", { username: username, password: password }),
  updateUserData: (id, data, token) => put(`/users/${id}`, data, token),
  resetPassword: (email) => post(`/users/resetpassword?email=${email}`, {}),
  resetPasswordByToken: (token, data) => put(`/users/webtoken/${token}`, data),
  verifyCode: (userId, code, token) =>
    put(`/users/${userId}/verify`, { code: code }, token),
  delete: (userId, token) => del(`/users/${userId}`, token),
  /*
  getAll: token => get('/users', token),
  getByEmail: (email, token) => get(`/users?email=${email}`, token),
  refreshToken: (userId, refreshToken) =>
    put(`/users/${userId}/refreshToken`, {refreshToken: refreshToken}),
  */
};

const schooltitle = {
  getAll: (token) => get("/schooltitle/",token)
}

const ebook = {
  getAll: (token) => get("/ebooks/", token),
  get: (id, token) => get(`/ebooks/${id}`, token),
};

const oauth = {
  getGoogleOAuthUrl: () => get(`/oauth/googleurl?os=web`),
};

const survey = {
  getById: (id, token) => get(`/surveys/${id}`, token),
  getNextQuestion: (instanceId, token) =>
    get(`/surveys/instances/${instanceId}/questions/next`, token),
  setQuestionAnswer: (instanceId, questionId, score, token) =>
    post(
      `/surveys/instances/${instanceId}/questions`,
      { questionId: questionId, score: score },
      token
    ),
  getInstance: (searchParam, token, callBack = () => null) => {
    let search = "";
    if (searchParam?.from && searchParam?.from.length > 0) {
      search = "?f=" + searchParam?.from;
    }
    if (searchParam?.to && searchParam?.to.length > 0) {
      if (search.length > 0) {
        search += "&";
      } else {
        search += "?";
      }
      search += "t=" + searchParam?.to;
    }
    if (searchParam?.company && searchParam?.company.length > 0) {
      if (search.length > 0) {
        search += "&";
      } else {
        search += "?";
      }
      search += "c=" + searchParam?.company;
    } if (searchParam?.code && searchParam?.code.length > 0) {
      if (search.length > 0) {
        search += "&";
      } else {
        search += "?";
      }
      search += "code=" + searchParam?.code;
    }
    if (searchParam?.format && searchParam?.format.length > 0) {
      if (search.length > 0) {
        search += "&";
      } else {
        search += "?";
      }
      search += "fmt=" + searchParam?.format;
      return download(
        `/surveys/instances${search}`,
        `export_questionari_${new Date().getTime()}.${searchParam.format}`,
        token,
        callBack
      );
    }
    return get(`/surveys/instances${search}`, token);
  },

  newInstance: (token) => post("/surveys/instances", {}, token),
  getProfile: (profileId, token) =>
    get(`/surveys/profiles/${profileId}`, token),
};

const view = {
  videoByCode: (code) => get(`/view/${code}`),
};
const company = {
  signup: (data, token) => post("/company", data, token),
  verifyCodeCompany: (code) => post("/company/verifycodecompany", { code }),
  getAll: (token) => get("/company/registrationcompany", token),
  getById: (id, token) => get(`/company/${id}`, token),
};

export default {
  ebook,
  user,
  oauth,
  survey,
  view,
  company,
  schooltitle
};
