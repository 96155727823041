import React from "react";
import { Card, Col, Divider, Form, Input, Row, Space, Typography } from "antd";

import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuthState } from "../providers/AuthProvider";

import { BaseButton, CarouselSlide } from "../components";
import { ReactComponent as GoogleLogo } from "../icons/icongoogle.svg";

const Login = () => {
  const { t } = useTranslation();
  const { googleOAuhtUrl, isLoading, login } = useAuthState();
  const location = useLocation();
  const { Title } = Typography;
  const { from } = location.state || { from: { pathname: "/welcome" } };

  const [errorMessage, setErrorMessage] = React.useState(null);

  const history = useHistory();

  const handleLogin = async ({ username, password }) => {
    //console.log("Login.handleSubmit", username);
    setErrorMessage(null);
    try {
      await login(username, password, handleLoginSuccess, handleLoginError);
      //console.log("Login.handleSubmit result", res);
    } catch (err) {
      console.log("Login.handleSubmit error", err);
    }
  };

  const handleLoginSuccess = (username, password) => {
    history.replace(from);
  };

  const handleLoginError = (err) => {
    setErrorMessage(t(`error_${err.eCode}`));
  };

  const handleGoogleLogin = () => {
    console.log("Login.handleGoogleLogin open link", googleOAuhtUrl);
    window.open(googleOAuhtUrl);
  };

  /* if (isLogged()) {
    return <Redirect to="/welcome" />;
  } */

  return (
    <div className="container">
      <Row
        align="middle"
        justify="center"
        className="card-style p-0"
        style={{
          border: "0px solid",
          margin: "auto",
          borderRadius: "20px",
        }}
      >
        <Col
          className="col-blue"
          md={{ span: 12 }}
          xs={{ span: 24 }}
          align="center"
        >
          <CarouselSlide />
        </Col>
        <Col
          className="col-lightblue"
          md={{ span: 12 }}
          xs={{ span: 24 }}
          align="center"
        >
          <Title
            className="title-blue"
            level={2}
            style={{ textAlign: "center" }}
          >
            {t("label_welcome").toUpperCase()}
          </Title>
          <Row justify="center">
            <Col xl={{ span: 14 }} xs={{ span: 18 }}>
              <Form
                size="large"
                requiredMark="optional"
                layout="vertical"
                onFinish={handleLogin}
              >
                <p className="paragraph_form" style={{ textAlign: "left" }}>
                  <strong> {t("label_username")} </strong>
                </p>
                <Form.Item
                  name="username"
                  type="email"
                  rules={[
                    { required: true, message: t("message_insert_username") },
                    { type: "email", message: t("message_invalid_mail") },
                  ]}
                >
                  <Input placeholder={t("label_email")} />
                </Form.Item>
                <p className="paragraph_form" style={{ textAlign: "left" }}>
                  <strong> {t("label_password")} </strong>
                </p>
                <Form.Item
                  type="password"
                  name="password"
                  rules={[
                    { required: true, message: t("message_form_password") },
                  ]}
                >
                  <Input.Password placeholder={t("label_password")} />
                </Form.Item>
                {errorMessage && (
                  <div
                    style={{
                      color: "#860303",
                      marginBottom: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    {errorMessage}
                  </div>
                )}
                <Row justify="center">
                  <Col
                    xl={{ span: 18 }}
                    lg={{ span: 16 }}
                    md={{ span: 20 }}
                    xs={{ span: 24 }}
                  >
                    <BaseButton
                      type="primary"
                      shape="round"
                      htmlType="submit"
                      block
                      loading={isLoading}
                    >
                      {t("login").toUpperCase()}
                    </BaseButton>
                  </Col>
                </Row>

                <Divider
                  style={{
                    minWidth: "30%",
                    width: "40%",
                    borderRadius: "20px",
                    border: "2px solid #035e86",
                    margin: "0px 0px 20px",
                    backgroundColor: "#035e86",
                  }}
                />
                <Row justify="center">
                  <Col
                    xl={{ span: 18 }}
                    lg={{ span: 16 }}
                    md={{ span: 20 }}
                    xs={{ span: 24 }}
                  >
                    <BaseButton
                      className="shadow-button"
                      shape="round"
                      block
                      onClick={handleGoogleLogin}
                      style={{ color: "#035E86" }}
                    >
                      <Space direction>
                        <GoogleLogo width="25px" />
                        {t("label_google").toUpperCase()}
                      </Space>
                    </BaseButton>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <Title
                level={5}
                style={{
                  color: "#035E86",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => history.push("/recoverpassword")}
              >
                <strong>{t("label_forgot_password")}</strong>
              </Title>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default Login;
