import React, { useState } from "react";
import {
  Col,
  Divider,
  Modal,
  notification,
  Row,
  Tooltip,
  Typography,
} from "antd";

import { useTranslation } from "react-i18next";
import { useAuthState } from "../providers/AuthProvider";
import { useHistory } from "react-router-dom";

import { BaseButton, CarouselSlide } from "../components";
import ReactCodeInput from "react-verification-code-input";

const VerifyCodeCompany = () => {
  const { t } = useTranslation();
  const { Title } = Typography;
  const { isLoading, verifyCompany } = useAuthState();
  const history = useHistory();
  const [authCode, setAuthCode] = useState("");
  const [showModal, setShowModal] = React.useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  const handleVerifyCode = async () => {
    await verifyCompany(authCode, onSuccess, onError);
  };
  const onSuccess = () => {
    console.log("VerifyCode.onSuccess going to /registration_success");
    history.push(`/signup/?c=${authCode}`);
  };

  const onError = (err) => {
    console.log("VerifyCode.onError", err);
    setShowModal(true);
    //openModal(t(`error_${err.eCode}`));
  };

  return (
    <>
      <Modal
        visible={showModal}
        footer={null}
        closable={false}
        maskClosable={false}
        onCancel={closeModal}
        centered
        bodyStyle={{ backgroundColor: "#D5E6ED" }}
      >
        <div style={{ textAlign: "center", color: "#035E86" }}>
          <Title level={4} style={{ color: "#035E86", fontWeight: "bold" }}>
            {t("label_verify_code").toUpperCase()}
          </Title>
          <Divider style={{ color: "#035E86", border: "1px solid " }}></Divider>
          <p style={{ fontSize: "20px" }}>{t("error_1101")}</p>
        </div>
        <Row justify="center">
          <Col span={6}>
            <BaseButton
              className="shadow-button"
              type="primary"
              block
              shape="round"
              size="large"
              style={{ marginBottom: "0px" }}
              onClick={closeModal}
            >
              {t("label_ok").toUpperCase()}
            </BaseButton>
          </Col>
        </Row>
      </Modal>
      <div className="container">
        <Row
          align="middle"
          justify="center"
          className="card-style p-0"
          style={{
            border: "0px solid",
            margin: "auto",
            borderRadius: "20px",
          }}
        >
          <Col
            className="col-blue"
            //xl={{ span: 12 }}
            md={{ span: 12 }}
            xs={{ span: 24 }}
            align="center"
          >
            <CarouselSlide />
          </Col>
          <Col
            //xl={{ span: 12 }}
            md={{ span: 12 }}
            xs={{ span: 24 }}
            className="col-lightblue"
            align="center"
          >
            <Title
              className="title-blue"
              level={2}
              style={{ textAlign: "center" }}
            >
              {t("id_code").toUpperCase()}
            </Title>
            <Row justify="center">
              <Col span={22}>
                <Title
                  level={4}
                  className="title-blue"
                  style={{ textAlign: "center" }}
                >
                  <strong>{t("message_id_code")}</strong>
                </Title>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "50px 0px",
                    textAlign: "center",
                  }}
                >
                  <ReactCodeInput
                    className="verify_code_input"
                    onComplete={setAuthCode}
                    loading={isLoading}
                    fields={8}
                    type={"text"}
                  />
                </div>

                <Row justify="center">
                  <Col xs={{ span: 12 }}>
                    <BaseButton
                      type="primary"
                      shape="round"
                      htmlType="submit"
                      block
                      size="large"
                      onClick={handleVerifyCode}
                    >
                      {t("label_verify_code").toUpperCase()}
                    </BaseButton>
                  </Col>
                </Row>
              </Col>
              <Title
                level={5}
                className="title-blue"
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  textAlign: "center",
                  marginBottom: "30px",
                }}
              >
                <Tooltip placement="bottom" title={t("definition_id_code")}>
                  <strong>{t("what_is_id_code")}</strong>
                </Tooltip>
              </Title>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default VerifyCodeCompany;
