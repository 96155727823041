import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Typography } from "antd";
import { useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import Loading from "./Loading";
import client from "../kairosClient";

import Password from "../images/pass.png";

const ResetPassword = () => {
  const { t } = useTranslation();
  const { Link, Title } = Typography;

  const { token } = useParams();
  const [processing, setProcessing] = useState(true);
  const [tokenValid, setTokenValid] = useState(null);
  const [resetDone, setResetDone] = useState(null);
  const [resetError, setResetError] = useState(null);

  useEffect(() => {
    console.log("ResetPassword.useEffect[] got this token", token);
    client.user
      .checkWebToken(token)
      .then((res) => setTokenValid(res.ok))
      .finally(() => setProcessing(false));
  }, []);

  const handleSubmit = (values) => {
    setProcessing(true);
    //console.log("ResetPassword.handleSubmit", values);
    client.user
      .resetPasswordByToken(token, values)
      .then((res) => {
        if (res.ok) {
          setResetDone(true);
        } else {
          setResetDone(false);
          return res.json();
        }
      })
      .then((res) => setResetError(res))
      .finally(() => setProcessing(false));
  };

  const showResetError = () => {
    if (resetError.eCode) {
      return <div>{t(`error_${resetError.eCode}`)}</div>;
    }
    return <div>Errore reset imprevisto, contattare l'assistenza</div>;
  };

  if (processing) {
    return <Loading />;
  }

  if (!tokenValid) {
    return (
      <div style={styles.wrapper}>
        <div style={styles.textContainer}>
          <div style={styles.text}>{t("message_invalid_token")}</div>
          <div style={styles.text}>{t("message_contact_helpdesk")}</div>
          <Link style={styles.text} href="mailto:heldesk@takeflight.it">
            helpdesk@takeflight.it
          </Link>
        </div>
      </div>
    );
  }

  if (resetDone) {
    return (
      <div style={styles.wrapper}>
        <div style={styles.textContainer}>
          <div style={styles.text}>{t("message_reset_password_success")}</div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="container">
        <div
          className="card-style p-0"
          style={{
            border: "0px solid",
            margin: "auto",
            borderRadius: "20px",
          }}
        >
          <Row align="middle" justify="center">
            <Col
              className="col-blue"
              md={{ span: 12 }}
              xs={{ span: 24 }}
              align="center"
              style={{
                minHeight: "600px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src={Password} alt="LoginImg" width="70%" height="70%" />
            </Col>
            <Col
              md={{ span: 12 }}
              xs={{ span: 24 }}
              className="col-lightblue"
              style={{
                minHeight: "600px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Row justify="center">
                <Col span={18}>
                  <Title
                    className="title-blue"
                    level={2}
                    style={{ textAlign: "center" }}
                  >
                    {t("label_reset_new_password").toUpperCase()}
                  </Title>
                  <Form
                    size="large"
                    className="form-password"
                    onFinish={handleSubmit}
                  >
                    <Form.Item
                      type="password"
                      name="password"
                      rules={[
                        { required: true, message: t("message_form_password") },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const pwdRegex =
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/gm;
                            if (pwdRegex.exec(value)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              t("message_invalid_password")
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        placeholder={t("password").concat(" *")}
                      />
                    </Form.Item>
                    <Form.Item
                      type="password"
                      name="confirmPassword"
                      dependencies={["password"]}
                      hasFeedback
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              t("message_invalid_confirm_password")
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        placeholder={t("confirm_password").concat(" *")}
                      />
                    </Form.Item>
                    {resetError && showResetError()}
                    <Row justify="center">
                      <Col>
                        <Button shape="round" htmlType="submit" type="primary">
                          {t("save").toUpperCase()}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default ResetPassword;

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    height: "50vh",
    alignItems: "center",
    justifyContent: "center",
  },
  textContainer: {
    width: "800px",
    padding: "32px",
    textAlign: "center",
    backgroundColor: "#f5f5f5",
    borderRadius: 8,
  },
  text: {
    fontSize: "1.4rem",
    fontWeight: "500",
    marginBottom: 16,
  },
};
