import React, { useState } from "react";
import { Card, Col, Divider, Modal, Row, Typography } from "antd";

import { useTranslation } from "react-i18next";
import { useAuthState } from "../providers/AuthProvider";
import { useHistory } from "react-router-dom";

import { BaseButton } from "../components";
import Verify from "../images/verifica.png";
import ReactCodeInput from "react-verification-code-input";

const VerifyCode = () => {
  const { t } = useTranslation();
  const { Title } = Typography;
  const { isLoading, newVerificationCode, verifyUser } = useAuthState();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);

  const handleVerifyCode = async (code) => {
    await verifyUser(code, onSuccess, onError);
  };

  const onSuccess = () => {
    console.log("VerifyCode.onSuccess going to /registration_success");
    history.push("/registration_success");
  };

  const onError = (err) => {
    console.log("VerifyCode.onError", err);
    openModal(t(`error_${err.eCode}`));
  };

  const openModal = (message) => {
    setModalMessage(message);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalMessage(null);
  };

  const handleNewVerificationCode = async () => {
    await newVerificationCode(
      () => {
        openModal(t("message_send_code"));
      },
      () => {
        openModal(
          "Errore imprevisto, riprovare fra qualche minuto. Se il problema persiste contattare l'assistenza."
        );
      }
    );
  };

  return (
    <>
      <Modal
        visible={showModal}
        footer={null}
        closable={false}
        maskClosable={false}
        onCancel={closeModal}
        bodyStyle={{ backgroundColor: "#D5E6ED" }}
      >
        <div style={{ textAlign: "center", color: "#035E86" }}>
          <Title level={4} style={{ color: "#035E86", fontWeight: "bold" }}>
            {t("label_verify_code").toUpperCase()}
          </Title>
          <Divider style={{ color: "#035E86", border: "1px solid " }}></Divider>
          <p style={{ fontSize: "20px" }}>{modalMessage}</p>
        </div>
        <Row justify="center">
          <Col span={6}>
            <BaseButton
              className="shadow-button"
              type="primary"
              block
              shape="round"
              size="large"
              style={{ marginBottom: "0px" }}
              onClick={closeModal}
            >
              {t("label_ok").toUpperCase()}
            </BaseButton>
          </Col>
        </Row>
      </Modal>

      <div className="container">
        <div
          className="card-style p-0"
          style={{
            border: "0px solid",
            margin: "auto",
            borderRadius: "20px",
          }}
        >
          <Row align="middle" justify="center">
            <Col
              className="col-blue"
              md={{ span: 12 }}
              xs={{ span: 24 }}
              align="center"
              style={{
                minHeight: "600px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src={Verify} alt="LoginImg" width="70%" height="70%" />
            </Col>
            <Col
              md={{ span: 12 }}
              xs={{ span: 24 }}
              className="col-lightblue"
              style={{
                minHeight: "600px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Row justify="center">
                <Col span={18}>
                  <Title
                    className="title-blue"
                    level={2}
                    style={{
                      textAlign: "center",
                      lineHeight: "34px",
                      textTransform: "uppercase",
                      marginBottom: "20px",
                    }}
                  >
                    {t("label_almost_there").toUpperCase()}
                  </Title>
                  <Title
                    level={5}
                    className="title-blue"
                    style={{ marginBottom: "20px" }}
                  >
                    <strong>{t("label_registration_completed")}</strong>
                  </Title>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "30px",
                      textAlign: "center",
                    }}
                  >
                    <ReactCodeInput
                      className="verify_code_input"
                      onComplete={handleVerifyCode}
                      loading={isLoading}
                    />
                  </div>

                  <Row justify="center">
                    <Col xs={{ span: 12 }}>
                      <BaseButton
                        type="primary"
                        shape="round"
                        htmlType="submit"
                        block
                        size="large"
                      >
                        {t("label_verify").toUpperCase()}
                      </BaseButton>
                    </Col>
                  </Row>

                  <Title
                    level={5}
                    className="title-blue"
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      textAlign: "center",
                      marginBottom: "30px",
                    }}
                    onClick={handleNewVerificationCode}
                  >
                    <strong>{t("label_new_code")}</strong>
                  </Title>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default VerifyCode;
