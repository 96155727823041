import React from "react";
import { Col, Row, Typography } from "antd";

const PrivacyPolicy = () => {
  const { Paragraph, Title } = Typography;

  return (
    <Row style={{ margin: "20px 0px" }} justify="center">
      <Col
        xl={{ span: 12 }}
        xs={{ span: 20 }}
        style={{
          backgroundColor: "#E7F0F4",
          borderRadius: "20px",
          padding: "20px",
        }}
      >
        <Title level={4}>
          INFORMATIVA AI CLIENTI SUL TRATTAMENTO DEI DATI PERSONALI AI SENSI
          DELL’ART. 13 DEL REGOLAMENTO EUROPEO (UE) 2016/679
        </Title>
        <Title level={5}>
          Questa informativa viene resa ai sensi del Regolamento (UE) 2016/679
          (di seguito “Regolamento” o “GDPR”) e descrive le modalità di
          trattamento dei dati personali raccolti dall’azienda.
        </Title>
        <Paragraph>
          <strong>Takeflight S.r.l.</strong>, sita in Via Serrabella 17, 40037
          Sasso Marconi (BO), P. IVA IT03622931206, in qualità di “Titolare del
          trattamento”, La informa ai sensi dell’art. 13 Regolamento UE n.
          2016/679 (in seguito, “GDPR”) che i Suoi dati saranno trattati con le
          modalità e per le finalità seguenti
        </Paragraph>
        <Title level={5}>
          Tipologia di dati trattati e oggetto del trattamento.
        </Title>
        <Paragraph>
          Il Titolare potrà trattare i dati da Lei comunicati sia in modo
          aggregato e anonimo che in modo identificativo (di seguito, “dati
          personali” o anche “dati”). Il Titolare del trattamento potrà
          utilizzare i dati da Lei comunicati sia per finalità di ricerca
          interna e miglioramento della piattaforma, sia per finalità
          commerciali cedendoli a terzi.
        </Paragraph>
        <Title level={5}>
          Titolare e responsabile del trattamento dei Dati
        </Title>
        <Paragraph>
          Il Titolare del trattamento è Takeflight S.r.l., sita in Via
          Serrabella 17, 40037 Sasso Marconi (BO), P. IVA IT03622931206, in
          persona del legale rappresentante pro tempore e CEO, Dott.ssa Carmen
          Dal Monte contattabile all’indirizzo{" "}
          <a href="mailto:takeflight@takeflight.it">takeflight@takeflight.it</a>
        </Paragraph>
        <Title level={5}>
          Finalità, Base Giuridica del trattamento e Tempi di Conservazione
        </Title>
        <Paragraph>
          Prestando il proprio consenso a quanto riportato nella presente
          informativa, l’Utente sceglie di fornire dati di natura identificativa
          e non identificativa, personali e non secondo quanto richiesto dalla
          piattaforma, che verranno raccolti e trattati dal Titolare per le
          seguenti finalità:
        </Paragraph>
        <ol style={{ listStyle: "lower-alpha" }}>
          <li>
            potranno essere elaborati per fini di ricerche interne attinenti
            all’oggetto sociale - (ossia l’elaborazione di algoritmi in grado di
            profilare le caratteristiche individuali di studenti e lavoratori,
            onde massimizzarne l’apprendimento, il rendimento e l’impiego) – e
            rendere maggiormente performanti i test di valutazioni offerti sulle
            piattaforme del Titolare;
          </li>
          <li>
            potranno essere comunicati e/o ceduti per fini promozionali, di
            marketing, commerciali in genere, di talent acquisition, scouting e
            recruting, nei settori bancario, finanziario, assicurativo, legale e
            relativi servizi, dell’istruzione, nonché nei settori del terziario
            rivolti all’offerta di svago nel tempo libero e cura della persona;
            Ciò a discrezione del Titolare del trattamento, senza ulteriori
            comunicazioni e secondo gli accordi contrattuali stipulati;
          </li>
          <li>
            per adempiere agli obblighi precontrattuali, contrattuali e fiscali
            derivanti da rapporti con Lei in essere o in fase di attivazione e
            proposta (art. 6, lett. b) Reg. 2016/679). I dati raccolti per
            obblighi contrattuali saranno conservati per il tempo necessario
            all’espletamento delle finalità suddette e secondo quanto previsto
            dalla legge;
          </li>
          <li>
            c) per adempiere agli obblighi previsti dalla legge, da un
            regolamento, dalla normativa comunitaria o da un ordine
            dell’Autorità (come ad esempio in materia di antiriciclaggio) (art.
            6 lett. c) Reg. 2016/679). I dati raccolti per obblighi di legge
            saranno conservati per il tempo necessario all’espletamento delle
            finalità suddette e secondo quanto previsto dalla legge;
          </li>
          <li>
            esercitare i diritti del Titolare, ad esempio il diritto di difesa
            in giudizio (art. 6 lett. f) Reg. 2016/679);
          </li>
          <li>
            per dare seguito alle specifiche richieste rivolte al Titolare per
            comunicazioni di natura informativa relative ai Servizi dello stesso
            Titolare, tramite messaggi di posta elettronica o di altri strumenti
            di comunicazione quali ad esempio il telefono (art. 6 lett. b) Reg.
            2016/679);
          </li>
        </ol>
        <Title level={5}>Conservazione</Title>
        <Paragraph>
          I dati raccolti per obblighi contrattuali saranno conservati per il
          tempo necessario all’espletamento delle finalità suddette e secondo
          quanto previsto dalla legge.
          <br /> In particolare:
        </Paragraph>

        <ul>
          <li>
            i dati oggetto di cessione a terzi non saranno oggetto di
            archiviazione su supporto informatico e verranno cancellati subito
            dopo la relativa comunicazione al cliente;{" "}
          </li>
          <li>
            i dati raccolti per obblighi fiscali/amministrativi o per obblighi
            contrattuali saranno conservati per il tempo necessario
            all’espletamento delle finalità suddette e secondo quanto previsto
            dalla legge;
          </li>
          <li>
            i dati raccolti per Finalità riconducibili al legittimo interesse
            del Titolare saranno trattenuti sino al soddisfacimento di tale
            interesse. L’Utente può ottenere ulteriori informazioni in merito
            all’interesse legittimo perseguito dal Titolare contattando il
            Titolare.
          </li>
        </ul>
        <Title level={5}>Modalità di trattamento</Title>
        <Paragraph>
          Il Titolare tratta i dati personali degli Utenti adottando le
          opportune misure di sicurezza volte ad impedire l’accesso, la
          divulgazione, la modifica o la distruzione non autorizzate dei Dati
          Personali. Il trattamento viene effettuato mediante strumenti
          informatici e/o telematici, con modalità organizzative e con logiche
          strettamente correlate alle finalità indicate. <br /> Oltre al
          Titolare, in alcuni casi, potrebbero avere accesso ai dati categorie
          di incaricati coinvolti nell’organizzazione aziendale (personale
          amministrativo, commerciale, legali, amministratori di sistema) ovvero
          soggetti esterni (come fornitori di servizi tecnici terzi, corrieri
          postali, hosting provider, società informatiche, agenzie di
          comunicazione).
          <br /> Il Titolare si riserva – se necessario – di nominare
          Responsabili del Trattamento ulteriori e diversi, congiuntamente o
          disgiuntamente da esso stesso, pro tempore o permanentemente (e
          comunque limitatamente al tempo di conservazione previsto dalla
          Legge). <br /> Tra i soggetti esterni che potranno essere nominati
          Responsabili Esterni del trattamento dei dati rientrano sia i Partner
          (i fornitori) che i Clienti. <br /> L’elenco aggiornato dei
          Responsabili potrà sempre essere richiesto al Titolare del
          Trattamento.
        </Paragraph>

        <Title level={5}>Luogo e Tempi</Title>
        <Paragraph>
          I dati sono trattati presso le sedi operative del Titolare ed in ogni
          altro luogo in cui le parti coinvolte nel trattamento siano
          localizzate. I dati sono trattati per il tempo necessario allo
          svolgimento del servizio richiesto, o richiesto dalle finalità
          descritte in questo documento, e l’interessato può sempre chiedere
          l’interruzione del Trattamento o la cancellazione dei dati.
        </Paragraph>

        <Title level={5}>Trasferimento dei Dati</Title>
        <Paragraph>
          I dati personali potranno essere trasferiti all’estero per finalità
          commerciali.
        </Paragraph>

        <Title level={5}>Diritti degli Interessati</Title>
        <Paragraph>
          Gli interessati – le persone fisiche identificate o identificabili cui
          si riferiscono i dati – possono esercitare specifici diritti sulla
          protezione dei dati, riportati nel seguente elenco:
        </Paragraph>
        <ol style={{ listStyle: "lower-alpha" }}>
          <li>
            diritto di accesso: diritto di ottenere dal Titolare la conferma che
            sia o meno in corso un trattamento di dati personali e in tal caso,
            di ottenere l’accesso ai dati personali ed informazioni di dettaglio
            riguardanti l’origine, le finalità, le categorie di dati trattati,
            destinatari di comunicazione e/o trasferimento dei dati ed altro
            ancora;
          </li>
          <li>
            diritto di rettifica: diritto di ottenere dal Titolare la rettifica
            dei dati personali inesatti senza ingiustificato ritardo, nonché
            l’integrazione dei dati personali incompleti, anche fornendo una
            dichiarazione integrativa;
          </li>
          <li>
            diritto alla cancellazione (“oblio”): diritto di ottenere dal
            Titolare la cancellazione dei dati personali senza ingiustificato
            ritardo nel caso in cui: i. i dati necessari non sono più necessari
            rispetto alle finalità del trattamento; ii. il consenso su cui si
            basa il trattamento è revocato e non sussiste altro fondamento
            giuridico per il trattamento; iii. i dati personali sono stati
            trattati illecitamente; iv. i dati personali devono essere
            cancellati per adempiere un obbligo legale;
          </li>
          <li>
            diritto di opposizione al trattamento: diritto di opporsi in
            qualsiasi momento al trattamento dei dati personali che hanno come
            base giuridica un interesse legittimo del Titolare;
          </li>
          <li>
            diritto alla limitazione: diritto di ottenere dal Titolare la
            limitazione del trattamento, nel caso in cui sia contestata
            l’esattezza dei dati personali (per il periodo necessario al
            Titolare per verificare l’esattezza di tali dati personali), se il
            trattamento è illecito e/o l’interessato si è opposto al
            trattamento;
          </li>
          <li>
            diritto alla portabilità dei dati: diritto di ricevere in un formato
            strutturato, di uso comune e leggibile da dispositivo automatico i
            dati personali e di trasmettere tali dati ad altro Titolare del
            trattamento, se tecnicamente fattibile, solo per i casi in cui il
            trattamento sia basato sul consenso o sul contratto e per i soli
            dati trattati tramite strumenti elettronici;
          </li>
          <li>
            diritto di proporre un reclamo all’autorità di controllo: fatto
            salvo ogni altro ricorso amministrativo o giurisdizionale,
            l’interessato che ritenga che il trattamento che lo riguarda violi
            il Regolamento ha diritto di proporre reclamo all’autorità di
            controllo dello Stato membro in cui risiede o lavora abitualmente,
            ovvero dello Stato in cui si è verificata la presunta violazione. I
            diritti possono essere esercitati contattando il Titolare al
            seguente indirizzo{" "}
            <a href="mailto:takeflight@takeflight.it">
              takeflight@takeflight.it
            </a>
            .
          </li>
        </ol>
        <Paragraph>
          <i>Informativa aggiornata al 27/01/2023</i>
        </Paragraph>
      </Col>
    </Row>
  );
};
export default PrivacyPolicy;
