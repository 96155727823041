import React from "react";
import { ConfigProvider, Layout } from "antd";
import itIT from "antd/lib/locale/it_IT";

import {
  matchPath,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";

import { useAuthState } from "./providers/AuthProvider";

import {
  Admin,
  CompanySignup,
  Courses,
  Download,
  Ebook,
  Forbidden,
  Home,
  Login,
  OAuthCallback,
  PageNotFound,
  PersonalData,
  Podcast,
  PrivacyPolicy,
  Profile,
  RecoverPassword,
  RecoverPasswordOk,
  RegistrationCompany,
  RegistrationSuccess,
  ResetPassword,
  Signup,
  Survey,
  SurveyResult,
  VerifyCode,
  VerifyCodeCompanyLandingPage,
  Video,
  ViewVideo,
  Webinar,
  Welcome,
} from "./pages";
import {
  EbookDetail,
  MainHeader,
  MainFooter,
  VideoGameDetail,
  VideoVrDetail,
  VideoArDetail,
} from "./components";

import "./App.less";
import bgImage from "./images/grid-min.png";
import Analytics from "./pages/Analytics";
import VerifyCodeCompany from "./pages/VerifyCodeCompany";

const PrivateRoute = ({ children, bypassLoading, admin, ...rest }) => {
  const { isAdmin, isLoading, isLogged, user } = useAuthState();

  console.log("PrivateRoute init ", isLoading, isLogged(), isAdmin(), user);
  if (admin && !isAdmin()) {
    return <Redirect to="/403" />;
  }

  if (!bypassLoading && isLoading) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLogged() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const App = () => {
  const location = useLocation();
  const { user } = useAuthState();

  const { Footer, Content } = Layout;

  //User loggato, ma non verificato
  if (user && !user.verified) {
    //Verifico se stiamo andando sulla pagina di verifica
    console.log("location: ", location);
    const match = matchPath(location.pathname, {
      path: "/verifycode",
      exact: true,
      strict: false,
    });
    if (match == null) {
      //No route to verify, redirect
      return (
        <Redirect
          to={{
            pathname: "/verifycode",
            state: { from: location },
          }}
        />
      );
    }
  }

  return (
    <ConfigProvider locale={itIT}>
      <Layout style={{ minHeight: "100vh" }}>
        {location.pathname !== "/companycode" && <MainHeader />}
        <Layout
          style={{ overflow: "hidden", position: "relative" }}
          className="bg-gradient"
        >
          <Content style={{ position: "relative" }}>
            <Switch>
              {/* PUBLIC ROUTES */}
              <Route path="/" exact component={Home} />
              <Route path="/login" component={Login} />
              <Route path="/recoverpassword" component={RecoverPassword} />
              <Route path="/recoverpasswordok" component={RecoverPasswordOk} />
              <Route path="/signup" component={Signup} />
              <Route path="/privacypolicy" component={PrivacyPolicy} />
              <Route
                path="/resetpassword/:token"
                exact
                component={ResetPassword}
              />
              <Route path="/callback" component={OAuthCallback} />
              <Route path="/download" component={Download} />
              <Route path="/view/:videocode" exact component={ViewVideo} />
              <Route path="/verifycodecompany">
                <VerifyCodeCompany />
              </Route>
              <Route path="/companycode">
                <VerifyCodeCompanyLandingPage />
              </Route>

              {/* PRIVATE ROUTES */}
              {/* <PrivateRoute path="/courses">
                <Courses />
              </PrivateRoute> */}
              <PrivateRoute path="/ebook" exact>
                <Ebook />
              </PrivateRoute>
              <PrivateRoute exact path="/ebook/:id">
                <EbookDetail />
              </PrivateRoute>
              <PrivateRoute path="/data">
                <PersonalData />
              </PrivateRoute>
              <PrivateRoute path="/webinar">
                <Webinar />
              </PrivateRoute>
              <PrivateRoute admin path="/companysignup">
                <CompanySignup />
              </PrivateRoute>
              <PrivateRoute path="/admin">
                <Admin />
              </PrivateRoute>
              {/*   <PrivateRoute path="/podcast">
                <Podcast />
              </PrivateRoute> */}
              {/* <PrivateRoute path="/video">
                <Video />
              </PrivateRoute>
              <PrivateRoute path="/videogame">
                <VideoGameDetail />
              </PrivateRoute>
              <PrivateRoute path="/videovr">
                <VideoVrDetail />
              </PrivateRoute>
              <PrivateRoute path="/videoar">
                <VideoArDetail />
              </PrivateRoute> */}

              <PrivateRoute path="/verifycode">
                <VerifyCode />
              </PrivateRoute>

              <PrivateRoute path="/registration_success">
                <RegistrationSuccess />
              </PrivateRoute>

              <PrivateRoute path="/profile">
                <Profile />
              </PrivateRoute>
              <PrivateRoute path="/secondary">
                <Profile secondary />
              </PrivateRoute>
              <PrivateRoute path="/survey" bypassLoading>
                <Survey />
              </PrivateRoute>
              <PrivateRoute path="/surveyresult">
                <SurveyResult />
              </PrivateRoute>

              <PrivateRoute path="/welcome">
                <Welcome />
              </PrivateRoute>

              <PrivateRoute admin path="/analytics">
                <Analytics />
              </PrivateRoute>

              <PrivateRoute admin path="/registrationcompany">
                <RegistrationCompany />
              </PrivateRoute>

              <Route path="/403" component={Forbidden} />
              {/* Se nessuna rotta precedente è stata invocata 404 */}
              <Route path="*" component={PageNotFound} />
            </Switch>
          </Content>
        </Layout>

        <Footer>
          <MainFooter />
        </Footer>
      </Layout>
    </ConfigProvider>
  );
};
export default App;
